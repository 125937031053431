<!--管理所属编辑-->
<template>
  <div id="personCompile" ref="personCompile">
    <a-modal
      :title="$t('information.a72')"
      :visible="visible"
      :footer="false"
      :getContainer="() => this.$refs.personCompile"
      @cancel="shut"
      centered
      :destroyOnClose="true"
      v-drag
    >
      <div v-loading="loading" :element-loading-text="$t('information.a1')">
        <!-- 搜索框 -->
        <a-input-search style="margin-bottom: 8px" :placeholder="$t('information.a291')" @change="onChange" />
        <!-- 树形图 -->
        <div class="tree_vessel">
          <a-tree
            :expanded-keys="expandedKeys"
            :auto-expand-parent="autoExpandParent"
            :tree-data="treeData"
            :replace-fields="replaceFields"
            :show-line="true"
            @expand="onExpand"
            @select="nodeSelect"
          >
            <template slot="custom" slot-scope="item">
                <span v-if="item.name.indexOf(searchValue) > -1">
                    {{ item.name.substr(0, item.name.indexOf(searchValue)) }}
                    <span style="color: #f50">{{ searchValue }}</span>
                    {{ item.name.substr(item.name.indexOf(searchValue) + searchValue.length) }}
                </span>
                <span v-else>{{ item.name }}</span>
            </template>
          </a-tree>
        </div>
        <div class="button">
          <a-button
            type="primary"
            style="width: 110px"
            :disabled="disabled_add_button"
            @click="addCard"
          >
            {{ $t("information.a35") }}
          </a-button>
          <a-button
            type="primary"
            style="width: 110px"
            :disabled="disabled_alteration_button"
            @click="updateCard"
          >
            {{ $t("information.a50") }}
          </a-button>
          <a-button
            type="primary"
            style="width: 110px"
            :disabled="disabled_remove_button"
            @click="deleteCard"
          >
            {{ $t("information.a31") }}
          </a-button>
          <a-button
            type="primary"
            style="width: 110px"
            :disabled="disabled_set_button"
            @click="permissionSetting"
          >
            {{ $t("information.a286") }}
          </a-button>
        </div>
        <div class="shut">
          <a-button type="primary" ghost style="width: 120px" @click="shut">
            {{ $t("information.a51") }}
          </a-button>
        </div>
      </div>
    </a-modal>
    <!-- 卡所属添加 -->
    <a-modal
      :title="$t('information.a52')"
      :width="350"
      centered
      :visible="add_visible"
      :getContainer="() => this.$refs.personCompile"
      @ok="addOk"
      @cancel="addCancel"
    >
      <div class="title"></div>
      <span>{{ $t("information.a53") }}</span>
      <a-input
        v-model="addName"
        :placeholder="$t('information.a54')"
        style="width: 180px"
      />
    </a-modal>
    <!-- 卡所属名称变更 -->
    <a-modal
      :title="$t('information.a55')"
      :width="350"
      centered
      :visible="update_visible"
      :getContainer="() => this.$refs.personCompile"
      @ok="updateOk"
      @cancel="updateCancel"
    >
      <div class="title"></div>
      <span>{{ $t("information.a53") }}:</span>
        <a-input v-model="parentname" allow-clear :placeholder="$t('information.a54')" style="width: 180px; margin-left: 15px"/>
    </a-modal>
  </div>
</template>

<script>
import { addPersonGr, updatePersonGr, deletePersonGr } from "../../../api/info";

export default {
  props: {
    visible: {
      type: Boolean,
    },
    data: {
      type: Array,
    },
    siteId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      disabled_add_button: true,
      disabled_alteration_button: true,
      disabled_remove_button: true,
      disabled_set_button: true,
      add_visible: false,
      update_visible: false,
      parentname: "",
      parentnameTitle: "",
      id: "",
      addName: "",
      replaceFields: { title: "name", children: "children", key: "id" },
      nodeName:[],
      expandedKeys: [],
      searchValue: '',
      autoExpandParent: true,
      treeData: [],
      dataList: []
    };
  },
  watch: {
    visible: {
      handler(newValue) {
        if (!newValue) {
          Object.assign(this.$data, this.$options.data());
        }else{
          const _gData = JSON.stringify(this.data)
          const gData = JSON.parse(_gData)
          this.treeData = gData
          this.dataHandle(gData)
        }
      }
    }
  },
  methods: {
    // 卡片所属编辑关闭函数
    shut() {
      this.$emit("shut", false);
    },
    // 添加按钮
    addCard() {
      this.add_visible = true;
    },
    // 添加卡所属确定按钮
    addOk() {
      this.add_visible = false;
      this.loading = true;
      let body = {
        name: this.addName,
        id: this.id,
      };
      let query = {
        siteId: this.siteId,
      };
      addPersonGr(body, query)
        .then((res) => {
          // console.log(res);
          this.loading = false;
          this.addName = "";
          let { errorCode } = res;
          // console.log(res)
          if (errorCode == "00") {
            this.$message.success(res.msg);
            let data = {
              siteGrId: this.$store.getters.sitegrId,
            };
            this.$store.dispatch("info/getPersonList", data);
          } else if (errorCode !== "00") {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 添加卡所属取消按钮
    addCancel() {
      this.add_visible = false;
    },
    // 更新按钮
    updateCard() {
      this.update_visible = true;
    },
    // 更新卡所属确定按钮
    updateOk() {
      let body = {
        name: this.parentname,
        id: this.id,
      };
      let query = {
        siteId: this.siteId,
      };
      this.update_visible = false;
      this.loading = true;
      updatePersonGr(body, query)
        .then((res) => {
          // console.log(res);
          this.loading = false;
          let { errorCode } = res;
          if (errorCode == "00") {
            this.parentnameTitle = this.parentname;
            this.$message.success("更新成功");
            let data = {
              siteGrId: this.$store.getters.sitegrId,
            };
            this.$store.dispatch("info/getPersonList", data);
          } else if (errorCode !== "00") {
            this.$message.error(res.msg);
            this.parentname = this.parentnameTitle;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 更新卡所属取消按钮
    updateCancel() {
      this.update_visible = false;
      this.parentname = this.parentnameTitle;
    },
    // 删除按钮
    deleteCard() {
      this.$confirm({
        content: `${this.$t("information.a56")}${this.parentname}${this.$t(
          "information.a57"
        )}`,
        centered: true,
        onOk: () => {
          let body = {
            name: this.parentname,
            id: this.id,
          };
          let query = {
            siteId: this.siteId,
          };
          this.loading = true;
          deletePersonGr(body, query)
            .then((res) => {
              // console.log(res);
              this.loading = false;
              let { errorCode } = res;
              if (errorCode == "00") {
                this.$message.success(res.msg);
                let data = {
                  siteGrId: this.$store.getters.sitegrId,
                };
                this.$store.dispatch("info/getPersonList", data);
              } else if (errorCode !== "00") {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        },
      });
    },
    // 权限设置按钮
    permissionSetting(){
      console.log(this.nodeName)
      this.$router.push({
          path: "/information/matchingSet",
          query: { id:this.id , node:this.nodeName.join('/')},
        })
    },
    // 树形选择回调
    nodeSelect(selectedKeys, info) {
      console.log(selectedKeys);
      console.log(info);
      let now = info.node.dataRef;
      let { hierarchyDepth } = now;
      let data = info.node.vcTreeNode;
      let parentname = now.name;
      let id = now.id;
      this.parentname = parentname;
      this.parentnameTitle = parentname;
      this.id = id;
      for (let i = 1; i < hierarchyDepth; i++) {
        data = data.vcTreeNode;
      }
      this.nodeName = []
      this.recursion(info.node)
      if (hierarchyDepth == 0) {
        this.disabled_add_button = true;
        this.disabled_alteration_button = true;
        this.disabled_remove_button = true;
        this.disabled_set_button = true
      } else if (hierarchyDepth == 1) {
        this.disabled_add_button = false;
        this.disabled_alteration_button = true;
        this.disabled_remove_button = true;
        this.disabled_set_button = false
      } else if (hierarchyDepth == 2) {
        this.disabled_add_button = false;
        this.disabled_alteration_button = false;
        this.disabled_remove_button = false;
        this.disabled_set_button = false
      } else if (hierarchyDepth == 3) {
        this.disabled_add_button = false;
        this.disabled_alteration_button = false;
        this.disabled_remove_button = false;
        this.disabled_set_button = false
      } else if (hierarchyDepth == 4) {
        this.disabled_add_button = false;
        this.disabled_alteration_button = false;
        this.disabled_remove_button = false;
        this.disabled_set_button = false
      } else if (hierarchyDepth == 5) {
        this.disabled_add_button = true;
        this.disabled_alteration_button = false;
        this.disabled_remove_button = false;
        this.disabled_set_button = false
      }
    },
    recursion(obj){
      this.nodeName.unshift(obj.dataRef.name)
      if(obj.vcTreeNode.dataRef !== undefined){
        this.recursion(obj.vcTreeNode)
      }
    },
    // 树形数据添加scopedSlots属性，扁平化树形数据
    dataHandle(node){
      for(let i = 0; i < node.length; i++){
        node[i].scopedSlots = { title:'custom' }
        const index = node[i]
        this.dataList.push({ id: index.id, name: index.name })
        if(index.children){ this.dataHandle(index.children) }
      }
    },
    // 树形控件展开/收起节点方法
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    // 搜索函数
    onChange(e) {
      const value = e.target.value
      const expandedKeys = this.dataList
        .map(item => {
          if (item.name.indexOf(value) > -1) {
            return this.getParentKey(item.id, this.treeData)
          }
          return null
        })
        .filter((item, i, self) => item && self.indexOf(item) === i )
      Object.assign(this, { expandedKeys, searchValue: value,autoExpandParent: true })
    },
    // 侧边栏递归函数
    getParentKey(id, tree){
        let parentKey
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i]
            if (node.children) {
                if (node.children.some(item => item.id === id)) {
                    parentKey = node.id
                } else if (this.getParentKey(id, node.children)) {
                    parentKey = this.getParentKey(id, node.children)
                }
            }
        }
        return parentKey
    }
  }
}
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.tree_vessel {
  height: 250px;
  overflow: auto;
  border: 1px solid rgba(12, 12, 12, 0.2);
  margin-bottom: 15px;
}
.button {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.input {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.shut {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid rgba(12, 12, 12, 0.2);
}
.title {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 700;
}
div /deep/.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
}
</style>