<!--人员信息-->
<template>
  <div
    id="informationGroup"
    v-loading="loading"
    :element-loading-text="$t('information.a1')"
  >
    <!-- 1 -->
    <!-- 头部 -->
    <div class="header">
      <my-headertitle>
        {{ $t("information.a3") }}
        <template v-slot:name>
          <span style="margin-right: 15px">{{ $t("information.a66") }}</span>
          <a-cascader
            style="width: 250px; margin-right: 150px"
            change-on-select
            :options="options"
            :field-names="{ label: 'name', value: 'id', children: 'children' }"
            :allowClear="false"
            v-model="select"
          />
        </template>
      </my-headertitle>
      <div class="queryBar">
        <a-row :gutter="16" type="flex" align="bottom">
          <a-col :span="3">
            <a-input
              :maxLength="30"
              allow-clear
              v-model="name"
              :placeholder="$t('information.a5')"
              :title="$t('information.a5')"
              style="width: 100%"
              v-InputText
            />
          </a-col>
          <a-col :span="3">
            <a-input
              :maxLength="30"
              allow-clear
              v-model="managementCode"
              :placeholder="$t('information.a7')"
              :title="$t('information.a7')"
              style="width: 100%"
              v-Input-NumberText
            />
          </a-col>
          <a-col :span="3">
            <a-input
              :maxLength="30"
              allow-clear
              v-model="kana"
              :placeholder="$t('information.a67')"
              :title="$t('information.a67')"
              style="width: 100%"
              v-InputText
            />
          </a-col>
          <a-col :span="3">
            <a-input
              :maxLength="30"
              allow-clear
              v-model="cardFaceNo"
              :placeholder="$t('information.a11')"
              :title="$t('information.a11')"
              style="width: 100%"
              v-Input-NumberText
            />
          </a-col>
          <a-col :span="3">
            <a-select v-model="ex_ninsyou" style="width: 100%">
              <a-select-option :value="0">
                {{ $t("information.a14") }}
              </a-select-option>
              <a-select-option :value="1">
                {{ $t("information.a15") }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="3">
            <a-button type="primary" @click="inquire">
              {{ $t("information.a29") }}
            </a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- 2 -->
    <!-- 主要内容区域 -->
    <div class="main" v-table-size:informationGroup="tableSize">
      <!-- 2.1 表格标题 -->
      <my-tabletitle>
        {{ $t("information.a69") }}
        <template v-slot:name>
          <a-button
            type="primary"
            icon="file-text"
            size="small"
            ghost
            :disabled="personList.length == 0"
            @click="csvExport"
            style="margin-right: 20px"
          >
            {{ $t("alarm.a51") }}
          </a-button>
          <a-popover placement="bottomRight" v-if="consumer">
            <template slot="content">
              <a-row style="width: 350px">
                <a-col
                  v-for="(item, index) in displayContent_consumer"
                  :key="index"
                  :span="12"
                >
                  <a-checkbox :disabled="item.disabled" v-model="item.select">
                    {{ item.title }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </template>
            <template slot="title">
              <span>{{ $t("alarm.a50") }}</span>
            </template>
            <a-button type="primary" size="small">
              {{ $t("alarm.a50") }}
            </a-button>
          </a-popover>
          <a-popover placement="bottomRight" v-if="door">
            <template slot="content">
              <a-row style="width: 350px">
                <a-col
                  v-for="(item, index) in displayContent_door"
                  :key="index"
                  :span="12"
                >
                  <a-checkbox :disabled="item.disabled" v-model="item.select">
                    {{ item.title }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </template>
            <template slot="title">
              <span>{{ $t("alarm.a50") }}</span>
            </template>
            <a-button type="primary" size="small">
              {{ $t("alarm.a50") }}
            </a-button>
          </a-popover>
        </template>
      </my-tabletitle>
      <!-- 2.2 表格 -->
      <a-table
        :columns="consume_column"
        :dataSource="personList"
        :scroll="size"
        :pagination="pagination"
        :rowKey="(record) => record.personId"
        v-if="consumer"
      >
        <template slot="chaozuo" slot-scope="text, record">
          <div>
            <a-popover placement="left">
              <template slot="content">
                <a-button
                  class="table_button"
                  type="primary"
                  @click="() => personDelete(record)"
                >
                  {{ $t("information.a31") }}
                </a-button>
                <a-button
                  class="table_button"
                  type="primary"
                  @click="() => infoGroupCompile(record)"
                >
                  {{ $t("information.a70") }}
                </a-button>
              </template>
              <a>{{ $t("information.a33") }}</a>
            </a-popover>
          </div>
        </template>
      </a-table>
      <a-table
        :columns="column"
        :dataSource="personList"
        :scroll="size"
        :pagination="pagination"
        :rowKey="(record) => record.personId"
        v-if="door"
      >
        <template slot="chaozuo" slot-scope="text, record">
          <div>
            <a-popover placement="left">
              <template slot="content">
                <a-button
                  class="table_button"
                  type="primary"
                  @click="() => personDelete(record)"
                  >{{ $t("information.a31") }}</a-button
                >
                <a-button
                  class="table_button"
                  type="primary"
                  @click="() => infoGroupCompile(record)"
                  >{{ $t("information.a70") }}</a-button
                >
                <a-button
                  class="table_button"
                  type="primary"
                  v-if="consumer"
                  @click="() => openAccountRecharge(record)"
                  >{{ $t("information.a71") }}</a-button
                >
              </template>
              <a>{{ $t("information.a33") }}</a>
            </a-popover>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 3 -->
    <!-- 底部 -->
    <div class="footer">
      <a-button type="primary" @click="openPersonCompile">
        {{ $t("information.a72") }}
      </a-button>
      <a-button type="primary" @click="infoGroupCompile">
        {{ $t("information.a73") }}
      </a-button>
      <a-button type="primary" v-if="consumer" @click="openCardType">
        {{ $t("information.a74") }}
      </a-button>
      <a-button type="primary" v-if="consumer" @click="openAccountRecharge">
        {{ $t("information.a75") }}
      </a-button>
      <a-button type="primary" @click="openBulk">
        {{ $t("information.a76") }}
      </a-button>
    </div>
    <!-- 管理所属编辑 -->
    <my-personcompile
      :visible="personCompile_visible"
      :data="compileOption"
      @shut="handleCancel"
      :siteId="siteId"
    />
    <!-- 批量注册 -->
    <my-bulk
      :visible="bulkVisible"
      @shut="bulkShut"
      :siteId="siteId"
      v-if="bulkVisible"
    />
    <!-- 卡类型设置的 -->
    <my-cardtype
      :visible="cardTypeVisible"
      @shut="closeCardType"
      :siteId="siteId"
    />
    <!-- 账户充值 -->
    <my-recharge
      :visible="rechargeVisible"
      @shut="closeAccountRecharge"
      :cardNo="cardNo"
      :siteId="siteId"
    />
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import personCompile from "./components/personCompile";
import bulkRegistration from "./components/bulkRegistration";
import cardTypeSet from "./components/cardTypeSet";
import accountRecharge from "./components/accountRecharge";
import global from "@/mixins/global.js";
import {
  getpersonData,
  personDelete,
  getpersonData_page,
} from "../../api/info";
import {
  tableSort,
  csvDownload,
  tableTitleDisplay,
  tableIndexDisplay,
} from "../../utils/utils";

export default {
  name: "informationGroup",
  mixins: [global],
  data() {
    return {
      loading: false,
      options: [],
      name: "",
      managementCode: "",
      kana: "",
      cardFaceNo: "",
      ex_ninsyou: 0,
      personList: [],
      size: { x: 2300, y: 240 },
      personCompile_visible: false,
      peopleManagement_visible: false,
      visible: false,
      select: [],
      compileOption: [],
      consumer: false,
      door: true,
      usageCondition: 10,
      status: 10,
      sitegrId: undefined,
      cardcompile: false,
      add_Data: false,
      compiledata: false,
      bulkVisible: false,
      cardTypeVisible: false,
      rechargeVisible: false,
      cardNo: "",
      columns: [
        {
          id: "1",
          title: "NO",
          dataIndex: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
          align: "center",
          fixed: "left",
        },
       
        {
          id: "2",
          title: this.$t("information.a7"),
          dataIndex: "managementCode",
          sorter: (a, b) => {
            return tableSort(a, b, "managementCode");
          },
          width: 130,
          align: "center",
        },
        {
          id: "3",
          title: this.$t("information.a5"),
          dataIndex: "name",
          sorter: (a, b) => {
            return tableSort(a, b, "name");
          },
          width: 110,
          align: "center",
        },
        {
          id: "4",
          title: this.$t("information.a67"),
          dataIndex: "kana",
          sorter: (a, b) => {
            return tableSort(a, b, "kana");
          },
          width: 150,
          align: "center",
        },
        {
          id: "5",
          title: this.$t("information.a11"),
          dataIndex: "cardFaceNo",
          sorter: (a, b) => {
            return tableSort(a, b, "cardFaceNo");
          },
          width: 130,
        },
        {
          id: "14",
          title: this.$t("information.a256"),
          dataIndex: "nationalityName",
          sorter: (a, b) => {
            return tableSort(a, b, "nationalityName");
          },
          width: 100,
          align: "center",
        },
        {
          id: "6",
          title: this.$t("information.a81"),
          dataIndex: "resistTime",
          sorter: (a, b) => {
            return tableSort(a, b, "resistTime");
          },
          width: 130,
          align: "center",
        },
        {
          id: "7",
          title: this.$t("information.a43"),
          dataIndex: "related_building",
          sorter: (a, b) => {
            return tableSort(a, b, "related_building");
          },
          align: "center",
        },
        {
          id: "8",
          title: this.$t("information.a82"),
          dataIndex: "personGr1",
          sorter: (a, b) => {
            return tableSort(a, b, "personGr1");
          },
          width: 130,
          align: "center",
        },
        {
          id: "9",
          title: this.$t("information.a83"),
          dataIndex: "personGr2",
          sorter: (a, b) => {
            return tableSort(a, b, "personGr2");
          },
          width: 300,
          align: "center",
        },
        {
          id: "10",
          title: this.$t("information.a84"),
          dataIndex: "personGr3",
          sorter: (a, b) => {
            return tableSort(a, b, "personGr3");
          },
          width: 200,
          align: "center",
        },
        {
          id: "11",
          title: this.$t("information.a85"),
          dataIndex: "personGr4",
          sorter: (a, b) => {
            return tableSort(a, b, "personGr4");
          },
          width: 180,
          align: "center",
        },
        {
          id: "12",
          title: this.$t("information.a86"),
          dataIndex: "personGr5",
          sorter: (a, b) => {
            return tableSort(a, b, "personGr5");
          },
          width: 180,
          align: "center",
        },
        {
          id: "13",
          title: this.$t("information.a48"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          fixed: "right",
          align: "center",
        },
      ],
      consume_column: [],
      consume_columns: [
        {
          id: "1",
          title: "NO",
          dataIndex: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
          align: "center",
          fixed: "left",
        },
        {
          id: "2",
          title: this.$t("information.a7"),
          dataIndex: "managementCode",
          sorter: (a, b) => {
            return tableSort(a, b, "managementCode");
          },
          width: 130,
          align: "center",
        },
        {
          id: "3",
          title: this.$t("information.a5"),
          dataIndex: "name",
          sorter: (a, b) => {
            return tableSort(a, b, "name");
          },
          width: 100,
          align: "center",
        },
        {
          id: "4",
          title: this.$t("information.a11"),
          dataIndex: "cardFaceNo",
          sorter: (a, b) => {
            return tableSort(a, b, "cardFaceNo");
          },
          width: 130,
          align: "center",
        },
        {
          id: "5",
          title: this.$t("information.a59"),
          dataIndex: "consumeCardTypeName",
          sorter: (a, b) => {
            return tableSort(a, b, "consumeCardTypeName");
          },
          width: 90,
          align: "center",
        },
        {
          id: "6",
          title: this.$t("information.a77"),
          dataIndex: "consumeType",
          sorter: (a, b) => {
            return tableSort(a, b, "consumeType");
          },
          customRender: (text, record, index) => {
            const type = record.consumeType;
            if (type == 1) {
              return this.$t("information.a78");
            } else if(type == 2) {
              return this.$t("information.a79");
            }else{
              return ''
            }
          },
          width: 100,
          align: "center",
        },
        {
          id: "7",
          title: this.$t("information.a80"),
          dataIndex: "conBalance",
          sorter: (a, b) => {
            return tableSort(a, b, "conBalance");
          },
          width: 90,
          align: "center",
        },
        {
          id: "8",
          title: this.$t("information.a81"),
          dataIndex: "resistTime",
          sorter: (a, b) => {
            return tableSort(a, b, "resistTime");
          },
          width: 130,
          align: "center",
        },
        {
          id: "9",
          title: this.$t("information.a43"),
          dataIndex: "related_building",
          sorter: (a, b) => {
            return tableSort(a, b, "related_building");
          },
          align: "center",
        },
        {
          id: "10",
          title: this.$t("information.a82"),
          dataIndex: "personGr1",
          sorter: (a, b) => {
            return tableSort(a, b, "personGr1");
          },
          width: 130,
          align: "center",
        },
        {
          id: "11",
          title: this.$t("information.a83"),
          dataIndex: "personGr2",
          sorter: (a, b) => {
            return tableSort(a, b, "personGr2");
          },
          width: 200,
          align: "center",
        },
        {
          id: "12",
          title: this.$t("information.a84"),
          dataIndex: "personGr3",
          sorter: (a, b) => {
            return tableSort(a, b, "personGr3");
          },
          width: 200,
          align: "center",
        },
        {
          id: "13",
          title: this.$t("information.a85"),
          dataIndex: "personGr4",
          sorter: (a, b) => {
            return tableSort(a, b, "personGr4");
          },
          width: 180,
          align: "center",
        },
        {
          id: "14",
          title: this.$t("information.a86"),
          dataIndex: "personGr5",
          sorter: (a, b) => {
            return tableSort(a, b, "personGr5");
          },
          width: 180,
          align: "center",
        },
        {
          id: "15",
          title: this.$t("information.a67"),
          dataIndex: "kana",
          sorter: (a, b) => {
            return tableSort(a, b, "kana");
          },
          width: 150,
          align: "center",
        },
        {
          id: "16",
          title: this.$t("information.a48"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          fixed: "right",
          align: "center",
        },
      ],
      column: [],
      timeout:null,
      displayContent_consumer:
        JSON.parse(localStorage.getItem("personalInformation_consumer")) || [],
      displayContent_door:
        JSON.parse(localStorage.getItem("personalInformation_door")) || [],
      pagination: {
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = pageSize;
          if(this.$platform.spider ==='NEW'){
            this.inquire();
          }
        },
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
           if(this.$platform.spider ==='NEW'){
              this.inquire();
          }
        },
      },
    };
  },
  watch: {
    // 监听站点ID调用获取卡所属列表的方法
    "$store.getters.sitegrId": {
      handler: function (newValue, oldValue) {
        this.pagination.total = 0
        this.personList = []
        this.loading = true
        if (newValue) {
          let data = { siteGrId: newValue }
          if(this.timeout !== null){
            clearTimeout(this.timeout)
          }
          this.timeout = setTimeout(()=>{
              this.$store
              .dispatch("info/getPersonList", data)
              .then((res) => {
                this.loading = false;
                let data = this.$store.getters.personList;
                let arr = [data[0].id, data[0].children[0].id];
                this.select = arr;
                this.options = data;
              })
              .catch((err) => {
                this.loading = false;
              })
            },1000)
        }
      },
      deep: true,
      immediate: true,
    },
    // 获取卡所属列表和列表选中值 personList
    "$store.getters.personList": {
      handler: function (newValue, oldValue) {
        let data = newValue;
        this.options = data;
        let val = this.select[0];
        let arr = [];
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i].id == val) {
            arr.push(this.options[i]);
          }
        }
        this.compileOption = arr;
      },
      deep: true,
    },
    // 卡所属选择内容截取
    select: {
      handler: function (newValue, oldValue) {
        this.pagination.total = 0;
        this.personList = [];
        let val = this.select[0];
        let arr = [];
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i].id == val) {
            arr.push(this.options[i]);
          }
        }
        if (newValue.length == 1) {
          this.select.push(arr[0].children[0].id);
        }
        this.compileOption = arr;
      },
      deep: true,
    },
    // 监听当前模块是否消费模块
    compileOption: {
      handler: function (newValue, oldValue) {
        if (newValue.length !== 0) {
          let type = newValue[0].contractTypes;
          let consume = type.indexOf(13);
          if (consume == -1) {
            this.consumer = false;
            this.door = true;
          } else {
            this.consumer = true;
            this.door = false;
          }
        }
      },
      deep: true,
    },
    // 监听表格表头显示内容生成表格表头
    displayContent_door: {
      handler(newValue) {
        const columns = this.columns;
        const name = "personalInformation_door";
        const data = tableIndexDisplay(name, columns, newValue);
        this.column = data;
      },
      deep: true,
      immediate: true,
    },
    // 监听表格表头显示内容生成表格表头
    displayContent_consumer: {
      handler(newValue) {
        const columns = this.consume_columns;
        const name = "personalInformation_consumer";
        const data = tableIndexDisplay(name, columns, newValue);
        this.consume_column = data;
      },
      deep: true,
      immediate: true,
    },
    // 监听页面模块类型加载表头和存储表头
    door: {
      handler(newVlaue) {
        const consumer = this.consumer;
        if (consumer) {
          const name = "personalInformation_consumer";
          const columns = this.consume_columns;
          const data = tableTitleDisplay(name, columns);
          if (data.columns == undefined) {
            this.displayContent_consumer = data.displayContent;
          } else {
            this.consume_column = data.columns;
            this.displayContent_consumer = data.displayContent;
          }
        } else {
          const name = "personalInformation_door";
          const columns = this.columns;
          const data = tableTitleDisplay(name, columns);
          if (data.columns == undefined) {
            this.displayContent_door = data.displayContent;
          } else {
            this.column = data.columns;
            this.displayContent_door = data.displayContent;
          }
        }
      },
      deep: true,
      immediate: true,
    },
    // 回退时刷新数据
    $route(to,from){
      console.log(to,from)
      const name = from.name
      const length = this.personList.length
      if(length !== 0 && name !== 'matchingSet'){
        this.inquire()
      }
    }
  },
  computed: {
    // 站点Id操作处理
    siteId() {
      let value = this.select[0];
      let arr = [];
      if (value) {
        arr = value.split("_");
      }
      let siteId = arr[0];
      return siteId;
    },
  },
  methods: {
    // 查询方法
    inquire() {
      this.loading = true;
      let data = {
        cardFaceNo: this.cardFaceNo,
        ex_ninsyou: this.ex_ninsyou,
        kana: this.kana.trim(),
        managementCode: this.managementCode,
        personGrId: this.select[this.select.length - 1],
        personName: this.name.trim(),
        siteId: this.siteId,
        pageNum: this.pagination.defaultCurrent,
        pageSize: this.pagination.pageSize,
      };
      // console.log(data);
      console.log(this.$platform);
      if(this.$platform.spider ==='OLD'){
          getpersonData(data)
          .then((res) => {
            this.personList = res.data;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }else{
          getpersonData_page(data)
          .then((res) => {
            console.log(res);
            let {
              data: { list, total },
            } = res;
            this.personList = list;
            this.pagination.total = total;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
      
    },
    // 删除按钮
    personDelete(value) {
      this.$confirm({
        title: `${this.$t("information.a56")} ${value.name} ${this.$t(
          "information.a57"
        )}`,
        centered: true,
        onOk: () => {
          this.loading = true;
          const data = {
            ids: [value.personId],
            siteId: this.siteId,
          };
          personDelete(data)
            .then((res) => {
              // console.log(res);
              this.loading = false;
              if (res.errorCode == "00") {
                this.$message.success(res.msg);
                let deletes = res.data.ids;
                let personList = this.personList;
                for (let i = 0; i < deletes.length; i++) {
                  let result = personList.findIndex(
                    (row) => row.personId === deletes[i]
                  );
                  personList.splice(result, 1);
                }
              } else if (res.errorCode !== "00") {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        },
      });
    },
    // 开启管理所属编辑
    openPersonCompile() {
      this.personCompile_visible = true;
    },
    // 管理所属编辑对话框关闭方法
    handleCancel(e) {
      this.personCompile_visible = e;
    },
    // 跳转人员管理页
    infoGroupCompile(value) {
      const siteGrId = value.siteGrId;
      if (siteGrId == undefined) {
        this.$router.push({ path: "/information/peopleManagement" });
      } else {
        console.log(value);
        const id = value.personId;
        this.$router.push({
          path: "/information/peopleManagement",
          query: { id, siteGrId },
        });
      }
    },
    // 人员管理弹窗关闭
    peopleManagementCancel(e) {
      this.peopleManagement_visible = false;
    },
    // 打开添加
    addData() {
      this.add_Data = true;
    },
    // 关闭添加弹窗
    addCancel(e) {
      this.add_Data = e;
    },
    // 关闭编辑弹窗
    compileCancel(e) {
      this.compiledata = e;
    },
    // 打开批量添加注册弹窗
    openBulk() {
      this.bulkVisible = true;
    },
    // 批量注册弹窗关闭
    bulkShut() {
      this.bulkVisible = false;
    },
    // 打开卡类型弹窗
    openCardType() {
      this.cardTypeVisible = true;
    },
    // 关闭卡类型弹窗
    closeCardType() {
      this.cardTypeVisible = false;
    },
    // 打开账户充值弹窗
    openAccountRecharge(value) {
      if (value.cardFaceNo !== undefined) {
        this.cardNo = value.cardFaceNo;
      }
      this.rechargeVisible = true;
    },
    // 关闭账户充值弹窗
    closeAccountRecharge() {
      this.rechargeVisible = false;
      this.cardNo = "";
    },
    // CSV导出
    csvExport() {
      const options = this.options;
      const select = this.select[0];
      let name;
      for (let i = 0; i < options.length; i++) {
        if (select == options[i].id) {
          name = options[i].name;
        }
      }
      const fileName = `${name}_${this.$t("information.a3")}`;
      const data = this.personList;
      const door = this.door;
      if (door) {
        const columns = this.column;
        csvDownload(data, columns, fileName);
      } else {
        const columns = this.consume_column;
        csvDownload(data, columns, fileName);
      }
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "my-personcompile": personCompile,
    "my-bulk": bulkRegistration,
    "my-cardtype": cardTypeSet,
    "my-recharge": accountRecharge,
  },
};
</script>

<style scoped>
#informationGroup {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px #bdbcbc;
  overflow: hidden;
}
.header {
  padding: 10px 20px;
}
.main {
  height: calc(100% - 178px);
  padding: 0px 20px;
  overflow: hidden;
}
.footer {
  display: flex;
  justify-content: flex-end;
  height: 52px;
  padding: 10px 20px;
  box-shadow: 0px 0px 10px #bdbcbc;
}
.footer button {
  margin-left: 15px;
}
.table_button {
  margin-right: 15px;
}
.queryBar {
  min-width: 1250px;
  padding: 10px;
  min-width: 850px;
  background-color: aliceblue;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
</style>

