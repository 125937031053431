<!--认证信息组--批量注册-->
<template>
  <div id="bulkRegistration" ref="bulkRegistration">
    <a-modal
      :title="$t('information.a87')"
      centered
      :width="1000"
      :visible="visible"
      :destroyOnClose="true"
      :getContainer="() => this.$refs.bulkRegistration"
      @cancel="Cancel"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="Cancel">
          {{ $t("information.a51") }}
        </a-button>
      </template>
      <div v-loading="loading" :element-loading-text="$t('information.a1')">
        <a-tabs v-model="brachType">
          <!-- 顾客信息 -->
          <a-tab-pane key="1" :tab="$t('information.a88')">
            <div class="main" v-if="brachType == '1'">
              <div class="tree-container">
                <div>
                  <span style="font-weight: bold">{{ $t("information.a89") }}</span>
                  <div class="tree">
                    <a-tree
                      showLine
                      :default-selected-keys="defaultPersonGrId"
                      :defaultExpandedKeys="defaultPersonGrId"
                      :tree-data="personGrBeanList"
                      :replace-fields="replaceFields"
                      @select="personSelect"
                      v-if="load"
                    />
                  </div>
                </div>
                <div>
                  <span style="font-weight: bold">{{ $t("information.a90") }}</span>
                  <div class="tree">
                    <a-tree
                      showLine
                      :default-selected-keys="defaultCredentialGr"
                      :defaultExpandedKeys="defaultCredentialGr"
                      :tree-data="credentialGrBeanList"
                      :replace-fields="replaceFields"
                      @select="cardSelect"
                      v-if="load"
                    />
                  </div>
                </div>
                <div>
                  <span style="font-weight: bold">{{ $t("information.a91") }}</span>
                  <div class="tree">
                    <a-tree
                      showLine
                      :default-selected-keys="defaultSiteId"
                      :defaultExpandedKeys="defaultSiteId"
                      :tree-data="baseInfoBeanList"
                      :replace-fields="replaceFields"
                      @select="baseSelect"
                      v-if="load"
                    />
                  </div>
                </div>
              </div>
              <div class="csv">
                <div class="csv-title">
                  <div>{{ $t("information.a92") }}</div>
                  <div>{{ $t("information.a93") }}</div>
                </div>
                <div class="csv-upload">
                  <div class="upload">
                    <a-upload
                      :file-list="fileList"
                      :remove="handleRemove"
                      :before-upload="beforeUpload"
                      style="width: 300px"
                    >
                      <a-button>{{ $t("information.a94") }} </a-button>
                    </a-upload>
                  </div>
                  <div>
                    <a-button
                      type="primary"
                      @click="fileupload"
                      icon="upload"
                      :disabled="uploadDisabled"
                      >{{ $t("information.a95") }}</a-button
                    >
                  </div>
                </div>
              </div>
              <div class="csv">
                <div class="csv-title">
                  <div>{{ $t("information.a96") }}</div>
                </div>
                <div class="csv-upload">
                  <div>
                    <a-checkbox v-model="downloadType">
                      {{ $t("information.a293") }}
                    </a-checkbox>
                  </div>
                  <div>
                    <a-checkbox v-model="downloadDeviceType">
                      {{ $t("information.a292") }}
                    </a-checkbox>
                  </div>
                  <div style="">
                    <a-checkbox v-model="downloadBrachType">
                      {{ $t("information.a294") }}
                    </a-checkbox>
                  </div>
                  <div>
                    <a-button
                      type="primary"
                      @click="gainFile"
                      icon="download"
                      :disabled="downloadDisabled"
                      >{{ $t("information.a98") }}</a-button
                    >
                  </div>
                </div>
              </div>
              <!--<div class="csv">-->
                <!--<div class="csv-title">-->
                  <!--&lt;!&ndash; 人员通行设备信息下载 &ndash;&gt;-->
                  <!--<div>{{ $t("information.a292") }}</div>-->
                <!--</div>-->
                <!--<div class="csv-upload">-->
                  <!--<div>-->
                    <!--&lt;!&ndash; <a-checkbox v-model="downloadType">-->
                      <!--{{ $t("information.a97") }}-->
                    <!--</a-checkbox> &ndash;&gt;-->
                  <!--</div>-->
                  <!--<div>-->
                    <!--<a-button-->
                      <!--type="primary"-->
                      <!--@click="staffPassModeEquipmentFile"-->
                      <!--:disabled="downloadDisabled"-->
                      <!--&gt;{{ $t("information.a98") }}</a-button-->
                    <!--&gt;-->
                  <!--</div>-->
                <!--</div>-->
              <!--</div>-->
            </div>
          </a-tab-pane>
          <!-- 指静脉管理信息 -->
          <a-tab-pane key="2" :tab="$t('information.a102')">
            <div class="main" v-if="brachType == '2'">
              <div class="tree-container">
                <div>
                  <span>{{ $t("information.a89") }}</span>
                  <div class="tree">
                    <a-tree
                      showLine
                      :default-selected-keys="defaultPersonGrId"
                      :defaultExpandedKeys="defaultPersonGrId"
                      :tree-data="personGrBeanList"
                      :replace-fields="replaceFields"
                      @select="personSelect"
                    />
                  </div>
                </div>
                <div>
                  <span>{{ $t("information.a90") }}</span>
                  <div class="tree">
                    <a-tree
                      showLine
                      :default-selected-keys="defaultCredentialGr"
                      :defaultExpandedKeys="defaultCredentialGr"
                      :tree-data="credentialGrBeanList"
                      :replace-fields="replaceFields"
                      @select="cardSelect"
                    />
                  </div>
                </div>
                <div>
                  <span>{{ $t("information.a91") }}</span>
                  <div class="tree">
                    <a-tree
                      showLine
                      :default-selected-keys="defaultSiteId"
                      :defaultExpandedKeys="defaultSiteId"
                      :tree-data="baseInfoBeanList"
                      :replace-fields="replaceFields"
                      @select="baseSelect"
                    />
                  </div>
                </div>
              </div>
              <div class="csv">
                <div class="csv-title">
                  <div>{{ $t("information.a99") }}</div>
                  <div>{{ $t("information.a93") }}</div>
                </div>
                <div class="csv-upload">
                  <div class="upload">
                    <a-upload
                      :file-list="fileList"
                      :remove="handleRemove"
                      :before-upload="beforeUpload"
                      style="width: 300px"
                    >
                      <a-button>{{ $t("information.a94") }} </a-button>
                    </a-upload>
                  </div>
                  <div>
                    <a-button
                      type="primary"
                      @click="fileupload"
                      icon="upload"
                      :disabled="uploadDisabled"
                      >{{ $t("information.a95") }}</a-button
                    >
                  </div>
                </div>
              </div>
              <div class="csv">
                <div class="csv-title">
                  <div>{{ $t("information.a100") }}</div>
                </div>
                <div class="csv-upload">
                  <div>
                    <a-checkbox v-model="downloadType">
                      {{ $t("information.a101") }}
                    </a-checkbox>
                  </div>
                  <div>
                    <a-button
                      type="primary"
                      @click="gainFile"
                      icon="download"
                      :disabled="downloadDisabled"
                      >{{ $t("information.a98") }}</a-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </a-tab-pane>
          <!-- 车牌信息  移除，车牌信息全部转移到车牌管理功能 chw 2022.12.23-->
          <!--<a-tab-pane key="6" :tab="$t('information.a103')">-->
            <!--<div class="main" v-if="brachType == '6'">-->
              <!--<div class="tree-container">-->
                <!--<div>-->
                  <!--<span>{{ $t("information.a89") }}</span>-->
                  <!--<div class="tree">-->
                    <!--<a-tree-->
                      <!--showLine-->
                      <!--:default-selected-keys="defaultPersonGrId"-->
                      <!--:defaultExpandedKeys="defaultPersonGrId"-->
                      <!--:tree-data="personGrBeanList"-->
                      <!--:replace-fields="replaceFields"-->
                      <!--@select="personSelect"-->
                    <!--/>-->
                  <!--</div>-->
                <!--</div>-->
                <!--<div>-->
                  <!--<span>{{ $t("information.a90") }}</span>-->
                  <!--<div class="tree">-->
                    <!--<a-tree-->
                      <!--showLine-->
                      <!--:default-selected-keys="defaultCredentialGr"-->
                      <!--:defaultExpandedKeys="defaultCredentialGr"-->
                      <!--:tree-data="credentialGrBeanList"-->
                      <!--:replace-fields="replaceFields"-->
                      <!--@select="cardSelect"-->
                    <!--/>-->
                  <!--</div>-->
                <!--</div>-->
                <!--<div>-->
                  <!--<span>{{ $t("information.a91") }}</span>-->
                  <!--<div class="tree">-->
                    <!--<a-tree-->
                      <!--showLine-->
                      <!--:default-selected-keys="defaultSiteId"-->
                      <!--:defaultExpandedKeys="defaultSiteId"-->
                      <!--:tree-data="baseInfoBeanList"-->
                      <!--:replace-fields="replaceFields"-->
                      <!--@select="baseSelect"-->
                    <!--/>-->
                  <!--</div>-->
                <!--</div>-->
              <!--</div>-->
              <!--<div class="csv">-->
                <!--<div class="csv-title">-->
                  <!--<div>{{ $t("information.a104") }}</div>-->
                  <!--<div>{{ $t("information.a93") }}</div>-->
                <!--</div>-->
                <!--<div class="csv-upload">-->
                  <!--<div class="upload">-->
                    <!--<a-upload-->
                      <!--:file-list="fileList"-->
                      <!--:remove="handleRemove"-->
                      <!--:before-upload="beforeUpload"-->
                      <!--style="width: 300px"-->
                    <!--&gt;-->
                      <!--<a-button>{{ $t("information.a94") }} </a-button>-->
                    <!--</a-upload>-->
                  <!--</div>-->
                  <!--<div>-->
                    <!--<a-button-->
                      <!--type="primary"-->
                      <!--@click="fileupload"-->
                      <!--:disabled="uploadDisabled"-->
                      <!--&gt;{{ $t("information.a95") }}</a-button-->
                    <!--&gt;-->
                  <!--</div>-->
                <!--</div>-->
              <!--</div>-->
              <!--<div class="csv">-->
                <!--<div class="csv-title">-->
                  <!--<div>{{ $t("information.a105") }}</div>-->
                <!--</div>-->
                <!--<div class="csv-upload">-->
                  <!--<div>{{ $t("information.a106") }}</div>-->
                  <!--<div>-->
                    <!--<a-button-->
                      <!--type="primary"-->
                      <!--@click="gainFile"-->
                      <!--:disabled="downloadDisabled"-->
                      <!--&gt;{{ $t("information.a98") }}</a-button-->
                    <!--&gt;-->
                  <!--</div>-->
                <!--</div>-->
              <!--</div>-->
            <!--</div>-->
          <!--</a-tab-pane>-->
          <!-- 电梯信息 -->
          <a-tab-pane key="7" :tab="$t('information.a257')">
            <div class="main" v-if="brachType == '7'">
              <div class="tree-container">
                <div>
                  <span>{{ $t("information.a89") }}</span>
                  <div class="tree">
                    <a-tree
                      showLine
                      :default-selected-keys="defaultPersonGrId"
                      :defaultExpandedKeys="defaultPersonGrId"
                      :tree-data="personGrBeanList"
                      :replace-fields="replaceFields"
                      @select="personSelect"
                    />
                  </div>
                </div>
                <div>
                  <span>{{ $t("information.a90") }}</span>
                  <div class="tree">
                    <a-tree
                      showLine
                      :default-selected-keys="defaultCredentialGr"
                      :defaultExpandedKeys="defaultCredentialGr"
                      :tree-data="credentialGrBeanList"
                      :replace-fields="replaceFields"
                      @select="cardSelect"
                    />
                  </div>
                </div>
                <div>
                  <span>{{ $t("information.a91") }}</span>
                  <div class="tree">
                    <a-tree
                      showLine
                      :default-selected-keys="defaultSiteId"
                      :defaultExpandedKeys="defaultSiteId"
                      :tree-data="baseInfoBeanList"
                      :replace-fields="replaceFields"
                      @select="baseSelect"
                    />
                  </div>
                </div>
              </div>
              <div class="csv">
                <div class="csv-title">
                  <div>{{ $t("information.a258") }}</div>
                  <div>{{ $t("information.a93") }}</div>
                </div>
                <div class="csv-upload">
                  <div class="upload">
                    <a-upload
                      :file-list="fileList"
                      :remove="handleRemove"
                      :before-upload="beforeUpload"
                      style="width: 300px"
                    >
                      <a-button>{{ $t("information.a94") }} </a-button>
                    </a-upload>
                  </div>
                  <div>
                    <a-button
                      type="primary"
                      @click="fileupload"
                      icon="upload"
                      :disabled="uploadDisabled"
                      >{{ $t("information.a95") }}</a-button
                    >
                  </div>
                </div>
              </div>
              <div class="csv">
                <div class="csv-title">
                  <div>{{ $t("information.a259") }}</div>
                </div>
                <div class="csv-upload">
                  <div></div>
                  <div>
                    <a-button
                      type="primary"
                      @click="gainFile"
                      icon="download"
                      :disabled="downloadDisabled"
                      >{{ $t("information.a98") }}</a-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </a-tab-pane>
          <!-- 图片信息 -->
          <a-tab-pane key="8" :tab="$t('information.a287')">
            <div class="main" v-if="brachType == '8'">
              <div class="tree-container">
                <div>
                  <span>{{ $t("information.a89") }}</span>
                  <div class="tree">
                    <a-tree
                            showLine
                            :default-selected-keys="defaultPersonGrId"
                            :defaultExpandedKeys="defaultPersonGrId"
                            :tree-data="personGrBeanList"
                            :replace-fields="replaceFields"
                            @select="personSelect"
                    />
                  </div>
                </div>
                <div>
                  <span>{{ $t("information.a90") }}</span>
                  <div class="tree">
                    <a-tree
                            showLine
                            :default-selected-keys="defaultCredentialGr"
                            :defaultExpandedKeys="defaultCredentialGr"
                            :tree-data="credentialGrBeanList"
                            :replace-fields="replaceFields"
                            @select="cardSelect"
                    />
                  </div>
                </div>
                <div>
                  <span>{{ $t("information.a91") }}</span>
                  <div class="tree">
                    <a-tree
                            showLine
                            :default-selected-keys="defaultSiteId"
                            :defaultExpandedKeys="defaultSiteId"
                            :tree-data="baseInfoBeanList"
                            :replace-fields="replaceFields"
                            @select="baseSelect"
                    />
                  </div>
                </div>
              </div>
              <div class="csv">
                <div class="csv-title">
                  <div>{{ $t("information.a288") }}</div>
                  <div>{{ $t("information.a93") }}</div>
                </div>
                <div class="csv-upload">
                  <div class="upload">
                    <a-upload
                            :file-list="fileList"
                            :remove="handleRemove"
                            :before-upload="beforeUpload"
                            style="width: 300px"
                    >
                      <a-button>{{ $t("information.a94") }} </a-button>
                    </a-upload>
                  </div>
                  <div>
                    <a-button
                            type="primary"
                            @click="fileupload"
                            icon="upload"
                            :disabled="uploadDisabled"
                    >{{ $t("information.a95") }}</a-button
                    >
                  </div>
                </div>
              </div>
              <div class="csv">
                <div class="csv-title">
                  <div>{{ $t("information.a289") }}</div>
                </div>
                <div class="csv-upload">
                  <div></div>
                  <div>
                    <a-button
                       type="primary"
                       @click="gainFile"
                       icon="download"
                       :disabled="true"
                    >{{ $t("information.a98") }}</a-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getbaseInfoList, uploadFile, dataDownload, getDetailsFile, staffPassModeEquipmentFileDownload } from "../../../api/info";

export default {
  props: {
    visible: {
      type: Boolean,
    },
    siteId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      brachType: "1",
      fileList: [],
      baseInfoBean: {},
      baseInfoBeanList: [],
      credentialGrBeanList: [],
      personGrBeanList: [],
      personGrId: "",
      credentialGrId: "",
      architectureId: "",
      defaultPersonGrId: [],
      defaultCredentialGr: [],
      defaultSiteId: [],
      downloadType: false,
      downloadDeviceType: false,
      downloadBrachType: false,
      filename: "",
      file: undefined,
      hierarchyDepth1: 3,
      hierarchyDepth2: 3,
      hierarchyDepth3: 2,
      load: false,
      replaceFields: { title: "name", children: "children", key: "id" },
      butloading:false
    };
  },
  watch: {
    brachType: {
      handler(newValue) {
        this.personGrId = this.defaultPersonGrId[0];
        this.credentialGrId = this.defaultCredentialGr[0];
        this.architectureId = this.defaultSiteId[0];
        this.downloadType = false;
        this.downloadBrachType = false;
        this.downloadDeviceType = false;
        this.fileList = [];
        this.hierarchyDepth1 = 3;
        this.hierarchyDepth2 = 3;
        this.hierarchyDepth3 = 2;
      },
    },
  },
  computed: {
    uploadDisabled() {
      const key1 = this.hierarchyDepth1;
      const key2 = this.hierarchyDepth2;
      const key3 = this.hierarchyDepth3;
      const fileList = this.fileList;
      if (key1 < 3 || key2 < 3 || key3 < 2 || fileList.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    downloadDisabled() {
      const key1 = this.hierarchyDepth1;
      const key2 = this.hierarchyDepth2;
      const key3 = this.hierarchyDepth3;
      var downloadDisabledTemp=false;
      console.log(key1, key2, key3, key1 < 2 || key2 < 2 || key3 < 1);

      if (key1 < 3 || key2 < 3 || key3 < 2) {
          downloadDisabledTemp = true;
      }
        //顾客信息数据下载，必须选择其中一个文件信息进行下载
        if(this.brachType==1 && !downloadDisabledTemp ){
            if(!(this.downloadType || this.downloadDeviceType || this.downloadBrachType)){
                downloadDisabledTemp = true;
           }
        }
        return downloadDisabledTemp;
    },
  },
  // 进入页时获取数据
  mounted() {
    console.log(this.siteId);
    this.loading = true;
    const data = {
      clientId: this.$store.getters.clientId,
      siteId: this.siteId,
    };
    console.log(data);
    getbaseInfoList(data)
      .then((res) => {
        console.log(res);
        this.loading = false;
        const {
          data: {
            baseInfoBean,
            credentialGrBeanList,
            personGrBeanList,
            defaultCredentialGr,
            defaultPersonGrId,
            defaultSiteId,
          },
        } = res;
        this.baseInfoBeanList = [baseInfoBean];
        this.credentialGrBeanList = credentialGrBeanList;
        this.personGrBeanList = personGrBeanList;
        this.defaultPersonGrId = [String(defaultPersonGrId)];
        this.defaultCredentialGr = [String(defaultCredentialGr)];
        this.defaultSiteId = [String(defaultSiteId) + "_0"];
        this.personGrId = String(defaultPersonGrId);
        this.credentialGrId = String(defaultCredentialGr);
        this.architectureId = String(defaultSiteId);
        this.load = true;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  methods: {
    // 弹窗关闭函数
    Cancel() {
      this.$emit("shut");
    },
    // 管理所属选择节点获取
    personSelect(selectedKeys, info) {
      // console.log(selectedKeys, info);
      const selected = info.selected;
      if (selected) {
        this.personGrId = selectedKeys[0];
        this.hierarchyDepth1 = info.node.dataRef.hierarchyDepth;
      } else {
        this.personGrId = "";
        this.hierarchyDepth1 = 0;
      }
    },
    // 卡所属选择节点获取
    cardSelect(selectedKeys, info) {
      // console.log(selectedKeys, info);
      const selected = info.selected;
      if (selected) {
        this.credentialGrId = selectedKeys[0];
        this.hierarchyDepth2 = info.node.dataRef.hierarchyDepth;
      } else {
        this.credentialGrId = "";
        this.hierarchyDepth2 = 0;
      }
    },
    // 建筑物选择节点获取
    baseSelect(selectedKeys, info) {
      // console.log(selectedKeys, info);
      const selected = info.selected;
      if (selected) {
        this.architectureId = selectedKeys[0];
        this.hierarchyDepth3 = info.node.dataRef.hierarchyDepth;
      } else {
        this.architectureId = "";
        this.hierarchyDepth3 = 0;
      }
    },
    // 文件上传
    fileupload() {
      this.loading = true;
      const { fileList } = this;
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("brachType", this.brachType);
      formData.append("credentialGrId", this.credentialGrId);
      formData.append("personGrId", this.personGrId);
      formData.append("siteId", this.architectureId.split('_')[0]);
      uploadFile(formData)
        .then((res) => {
          console.log(res);
          this.loading = false;
          const fileUrl = res.data.fileUrl
          const addCount = res.data.addCount
          const errorCount = res.data.errorCount
          const sumCount = res.data.sumCount
          const updateCount = res.data.updateCount
          if(fileUrl){
              if (res.errorCode == "00") {
                  this.$confirm({
                  title: res.msg,
                  centered: true,
                  content: h => (<div>
                                  <a-row>
                                    <a-col span="12">
                                     {`${this.$t("information.a275")}${sumCount}`}
                                    </a-col>
                                    <a-col span="12">
                                     {`${this.$t("information.a276")}${addCount}`}
                                    </a-col>
                                    </a-row>
                                    <a-row>
                                    <a-col span="12">
                                     {`${this.$t("information.a277")}${updateCount}`}
                                    </a-col>
                                    <a-col span="12">
                                      {`${this.$t("information.a278")}${errorCount}`}
                                    </a-col>
                                  </a-row>
                                </div>),
                  icon:() => <a-icon type="check-circle" style="color:#00FF33"/>,
                  okText: this.$t("information.a279"),
                  cancelText: this.$t("information.a209"),
                  onOk:() => {
                    return new Promise((resolve, reject)=>{
                      getDetailsFile(fileUrl)
                      .then(res=>{
                        resolve()
                        this.download(res,`${this.$t("information.a280")}.zip`)
                      })
                      .catch(err=>{
                        console.log(err)
                      })
                    })
                    .catch(err=>{
                      console.log()
                    })
                  },
                })
            } else {
                this.$confirm({
                title: res.msg,
                centered: true,
                content: h => (<div>
                                <a-row>
                                    <a-col span="12">
                                     {`${this.$t("information.a275")}${sumCount}`}
                                    </a-col>
                                    <a-col span="12">
                                     {`${this.$t("information.a276")}${addCount}`}
                                    </a-col>
                                    </a-row>
                                    <a-row>
                                    <a-col span="12">
                                     {`${this.$t("information.a277")}${updateCount}`}
                                    </a-col>
                                    <a-col span="12">
                                      {`${this.$t("information.a278")}${errorCount}`}
                                    </a-col>
                                  </a-row>
                              </div>),
                icon:() => <a-icon type="close-circle" style="color:red" />,
                okText: this.$t("information.a279"),
                cancelText: this.$t("information.a209"),
                onOk:()=>{
                  return new Promise((resolve, reject)=>{
                      getDetailsFile(fileUrl)
                      .then(res=>{
                        resolve()
                        this.download(res,`${this.$t("information.a280")}.zip`)
                      })
                      .catch(err=>{
                        console.log(err)
                      })
                    })
                    .catch(err=>{
                      console.log()
                    })
                },
              });
            }
          }else{
            this.$error({
                title: res.msg,
                centered: true,
              }
            )
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        })
    },
    // 下载文件     change ==> siteId: this.siteId.split("_")[0]  >>to>> this.architectureId.split('_')[0]  by pzj 20230315
    gainFile() {
      this.loading = true;
      //顾客信息数据下载文件特殊化处理 add by pzj 20230701
       var  downloadTypeSelect=0;
      if(this.brachType==1){
          //人员信息下载 高位1 代表选择
         if(this.downloadType){
             downloadTypeSelect=downloadTypeSelect+100;
         }
         //人员通行模式设备下载 第二位1代表选择
         if(this.downloadDeviceType){
             downloadTypeSelect=downloadTypeSelect+10;
         }
          //最后一次批量人员导入文件下载 最低位 1 代表选择
         if(this.downloadBrachType){
             downloadTypeSelect=downloadTypeSelect+1;
         }
      }else {
          downloadTypeSelect=this.downloadType ? 1 : 0;
      }


      const data = {
        brachType: this.brachType,
        credentialGrId: this.credentialGrId,
        // downloadType: this.downloadType ? 1 : 0,
        downloadType: downloadTypeSelect,
        personGrId: this.personGrId,
        siteId: this.architectureId.split('_')[0],
      };
      console.log(data);
      dataDownload(data)
        .then((res) => {
          console.log(res);
          this.loading = false;
          this.filename = decodeURIComponent(res.headers["content-disposition"])
            .split(";")[1]
            .split("=")[1];
          this.file = res.data;
          console.log(this.filename)
          this.download(this.file,this.filename);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 下载文件
    download(file,filename) {
      let url = window.URL.createObjectURL(file);
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    },
    // 删除选中文件
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    //增加  xlsx文件格式的兼容上传 add by pzj
    beforeUpload(file) {
      let { name } = file;
      let value = name.split(".")[1];
      if ((value == "csv" ||value == "xlsx")  && this.brachType !== '8') {
        this.fileList = [file];
        return false;
      } else if(value == "zip" && this.brachType == '8'){
          this.fileList = [file]
          return false
      } else if(this.brachType !== '8' && value !== "csv"){
        this.$error({
          title: this.$t("information.a107"),
          centered: true,
          content: this.$t("information.a108"),
        });
        // this.fileList = [];
        return false;
      }else if(this.brachType == '8' && value !== "zip"){
          this.$error({
              title: this.$t("information.a107"),
              centered: true,
              content: this.$t("information.a278")
          })
          // this.fileList = []
          return false
      }
      //   this.fileList = [file];
      //   return false;
    },
    //下载人员通行设备信息
    staffPassModeEquipmentFile(){
      this.loading = true;
      const data = {
        siteId: this.architectureId.split('_')[0],
      };

      console.log(data);
      staffPassModeEquipmentFileDownload(data)
        .then((res) => {
          console.log(res);
          this.loading = false;
          this.filename = decodeURIComponent(res.headers["content-disposition"])
            .split(";")[1]
            .split("=")[1];
          this.file = res.data;
          console.log(this.filename)
          this.download(this.file,this.filename);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
div /deep/.ant-modal-body {
  padding: 0px 20px;
}
div /deep/.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
}
.tree-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.tree {
  margin-top: 8px;
  width: 300px;
  height: 350px;
  overflow: auto;
  border: 1px solid rgba(12, 12, 12, 0.2);
}
.csv {
  margin-bottom: 20px;
  border: 1px solid rgba(12, 12, 12, 0.2);
}
.csv-title {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: rgba(12, 12, 12, 0.1);
  border-bottom: 1px solid rgba(12, 12, 12, 0.2);
}
.csv-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.upload {
  width: 300px;
  height: 65px;
}
</style>