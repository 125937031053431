<!--人员信息组账户充值-->
<template>
  <div id="accountrecharge" ref="accountrecharge">
    <!-- 账户充值弹窗 -->
    <a-modal
      :title="$t('information.a233')"
      centered
      :width="900"
      :visible="visible"
      :getContainer="() => this.$refs.accountrecharge"
      @cancel="Cancel"
      :destroyOnClose="true"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="Cancel">
          {{ $t("information.a209") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :disabled="disabled"
          @click="recharge"
        >
          {{ $t("information.a234") }}
        </a-button>
      </template>
      <!-- 主内容区域 -->
      <div
        class="main"
        v-loading="loading"
        :element-loading-text="$t('information.a1')"
      >
        <!-- 查询栏 -->
        <div class="row_inquire">
          <div>
            <span>{{ $t("information.a235") }}</span>
            <a-input
              v-model="card_No"
              style="width: 200px"
              allow-clear
              :placeholder="$t('information.a236')"
            />
          </div>
          <div>
            <a-button key="submit" type="primary" @click="inquire">
              {{ $t("information.a174") }}
            </a-button>
          </div>
        </div>
        <!-- 基本信息栏 -->
        <div class="info_row margin">
          <div class="info_row_title">
            <b>{{ $t("information.a237") }}</b>
          </div>
          <div class="info_row_main">
            <div>
              <p class="piece_title">{{ $t("information.a5") }}</p>
              <span class="piece_content">{{
                data.personBean.name | none
              }}</span>
            </div>
            <div>
              <p class="piece_title">{{ $t("information.a7") }}</p>
              <span class="piece_content">{{
                data.personBean.managementCode | none
              }}</span>
            </div>
            <div>
              <p class="piece_title">{{ $t("information.a11") }}</p>
              <span class="piece_content">{{
                data.consumeCardBean.cardNo | none
              }}</span>
            </div>
            <div>
              <p class="piece_title">{{ $t("information.a24") }}</p>
              <span class="piece_content">{{ status }}</span>
            </div>
            <div>
              <p class="piece_title">{{ $t("information.a59") }}</p>
              <span class="piece_content">{{
                data.consumeCardBean.consumeCardTypeName | none
              }}</span>
            </div>
            <div>
              <p class="piece_title">{{ $t("information.a222") }}</p>
              <span class="piece_content">{{
                data.consumeCardBean.activationTime | none
              }}</span>
            </div>
            <div>
              <p class="piece_title">{{ $t("information.a155") }}</p>
              <span class="piece_content">{{
                data.consumeCardBean.expirationTerm | none
              }}</span>
            </div>
          </div>
          <div class="info_row_mains">
            <div class="margin_right">
              <p class="piece_title">{{ $t("information.a152") }}</p>
              <span class="piece_content">{{
                data.personGrBean.name | none
              }}</span>
            </div>
            <div>
              <p class="piece_title">{{ $t("information.a42") }}</p>
              <span class="piece_content">{{ genusname }}</span>
            </div>
          </div>
        </div>
        <!-- 消费信息栏 -->
        <div class="info_row margin">
          <div class="info_row_title">
            <b>{{ $t("information.a238") }}</b>
          </div>
          <div class="info_row_main">
            <div class="margin">
              <p class="piece_title">{{ $t("information.a239") }}</p>
              <span class="piece_content">{{
                data.consumeCardBean.conBalance | none
              }}</span>
            </div>
            <div>
              <p class="piece_title">{{ $t("information.a240") }}</p>
              <span class="piece_content">{{
                data.consumeCardBean.consumeTypeName | none
              }}</span>
            </div>
            <div>
              <p class="piece_title">{{ $t("information.a241") }}</p>
              <span class="piece_content">{{
                data.personBean.registrationTime | none
              }}</span>
            </div>
            <div>
              <p class="piece_title">{{ $t("information.a242") }}</p>
              <span class="piece_content">{{
                data.personBean.updatedTime | none
              }}</span>
            </div>
            <div>
              <p class="piece_title">{{ $t("information.a243") }}</p>
              <span class="piece_content">{{
                data.consumeCardBean.recentlyReChargeMoney | none
              }}</span>
            </div>
            <div>
              <p class="piece_title">{{ $t("information.a244") }}</p>
              <span class="piece_content">{{
                data.consumeCardBean.recentlyRechargeTime | none
              }}</span>
            </div>
            <div>
              <p class="piece_title">{{ $t("information.a47") }}</p>
              <span class="piece_content">{{ $t("information.a245") }}</span>
            </div>
          </div>
        </div>
        <!-- 充值栏 -->
        <div class="info_row">
          <div class="info_row_title">
            <b>{{ $t("information.a234") }}</b>
          </div>
          <div class="Pay_main">
            <div class="Pay_piece">
              <p class="Pay_title">{{ $t("information.a246") }}</p>
              <a-input
                v-model="Paymoney"
                style="width: 200px"
                allow-clear
                :disabled="disabled"
                :placeholder="$t('information.a247')"
              />
            </div>
            <div>
              <a-button
                class="but"
                type="primary"
                ghost
                :disabled="disabled"
                @click="moneySelect('10')"
              >
                ￥10
              </a-button>
              <a-button
                class="but"
                type="primary"
                ghost
                :disabled="disabled"
                @click="moneySelect('20')"
              >
                ￥20
              </a-button>
              <a-button
                class="but"
                type="primary"
                ghost
                :disabled="disabled"
                @click="moneySelect('50')"
              >
                ￥50
              </a-button>
              <a-button
                class="but"
                type="primary"
                ghost
                :disabled="disabled"
                @click="moneySelect('100')"
              >
                ￥100
              </a-button>
              <a-button
                class="but"
                type="primary"
                ghost
                :disabled="disabled"
                @click="moneySelect('200')"
              >
                ￥200
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getRechargeCardInfo, recharge } from "../../../api/info";

export default {
  props: {
    visible: {
      type: Boolean,
    },
    cardNo: {
      type: String,
    },
    siteId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      data: {
        personBean: {},
        consumeCardBean: {},
        personGrBean: {},
      },
      arr: [],
      Paymoney: "",
      card_No: "",
      disabled: true,
    };
  },
  filters: {
    none(value) {
      if (value) {
        return value;
      } else {
        return "----";
      }
    },
  },
  watch: {
    cardNo: {
      handler(newValue) {
        console.log(newValue);
        if (newValue.length > 0) {
          this.card_No = newValue;
          this.inquire();
        } else {
          Object.assign(this.$data, this.$options.data());
        }
      },
    },
  },
  computed: {
    genusname() {
      const children = this.data.personGrBean.children;
      this.arr = [];
      if (children !== undefined) {
        this.recursion(children);
        return this.arr.join("/");
      } else {
        return "----";
      }
    },
    status() {
      let value = this.data.consumeCardBean.status;
      switch (value) {
        case 1:
          return this.$t("information.a37");
          break;
        case 2:
          return this.$t("information.a38");
          break;
        case 3:
          return this.$t("information.a39");
          break;
        default:
          return "----";
      }
    },
  },
  methods: {
    //账户充值弹窗关闭方法
    Cancel() {
      this.$emit("shut");
    },
    // 金额选择
    moneySelect(value) {
      this.Paymoney = value;
    },
    // 账户查询方法
    inquire() {
      const cardNo = this.card_No;
      if (cardNo.length > 0) {
        this.loading = true;
        const data = {
          cardNo,
          siteId: this.siteId,
        };
        console.log(data);
        getRechargeCardInfo(data)
          .then((res) => {
            console.log(res);
            this.loading = false;
            const { data } = res;

            if (data.consumeCardBean !== null) {
              const {
                consumeCardBean: { consumeType },
              } = data;
              this.data = data;
              if (consumeType == 1) {
                this.disabled = true;
              } else if (consumeType == 2) {
                this.disabled = false;
              }
            } else {
              this.$error({
                title: this.$t("information.a248"),
                content: this.$t("information.a249"),
                centered: true,
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      } else {
        this.$error({
          title: this.$t("information.a250"),
          content: this.$t("information.a236"),
          centered: true,
        });
      }
    },
    // 充值方法
    recharge() {
      const Paymoney = Number(this.Paymoney);
      if (Paymoney > 0) {
        this.loading = true;
        const data = {
          cardId: this.data.consumeCardBean.cardId,
          money: Number(this.Paymoney).toFixed(2),
          siteId: this.siteId,
        };
        console.log(data);
        recharge(data)
          .then((res) => {
            console.log(res);
            this.loading = false;
            if (res.errorCode == "00") {
              this.$message.success(res.msg);
              this.inquire();
              this.Paymoney = "";
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      } else {
        this.$error({
          title: this.$t("information.a251"),
          content: this.$t("information.a247"),
          centered: true,
        });
      }
    },
    // 递归遍历方法
    recursion(arr) {
      for (let i = 0; i < arr.length; i++) {
        this.arr.push(arr[i].name);
        if (arr[i].children !== null) {
          this.recursion(arr[i].children);
        }
      }
    },
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
div /deep/.ant-modal-body {
  background: #f3f3f7;
  padding: 20px;
}
.row_inquire {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 10px 20px;
  box-shadow: 0px 0px 10px #bdbcbc;
  border-radius: 3px;
  margin-bottom: 20px;
}
.info_row {
  background: #ffffff;
  padding: 10px;
  box-shadow: 0px 0px 10px #bdbcbc;
  border-radius: 3px;
}
.info_row_title {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  font-size: 16px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.2);
}
.info_row_title::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 20px;
  background-color: #7682ce;
  margin-right: 20px;
}
.info_row_main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
}
.info_row_mains {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}
.margin_right {
  margin-right: 30px;
}
.piece_title {
  font-size: 14px;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.45);
}
.piece_content {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}
.margin {
  margin-bottom: 20px;
}
.Pay_main {
  display: flex;
  align-items: flex-end;
  padding: 10px;
}
.Pay_piece {
  margin-right: 30px;
}
.Pay_title {
  margin-bottom: 5px;
}
.but {
  margin-right: 15px;
}
</style>