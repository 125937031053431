<!--认证信息组--卡类型设置-->
<template>
  <div id="cardtypeset" ref="cardtypeset">
    <a-modal
      :title="$t('information.a109')"
      centered
      :width="400"
      :visible="visible"
      :getContainer="() => this.$refs.cardtypeset"
      @cancel="Cancel"
      :destroyOnClose="true"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="Cancel">
          {{ $t("information.a51") }}
        </a-button>
        <a-button key="submit" type="primary" @click="cardTypeSet">
          {{ $t("information.a65") }}
        </a-button>
      </template>
      <div v-loading="loading" :element-loading-text="$t('information.a1')">
        <div class="main" ref="main">
          <my-tabletitle> {{ $t("information.a110") }}</my-tabletitle>
          <a-table
            :columns="columns"
            :data-source="data"
            :pagination="false"
            :rowKey="(record) => record.consumeCardType"
          >
            <template slot="operation" slot-scope="text, record">
              <a-input v-model="record.consumeCardTypeName" allow-clear />
            </template>
          </a-table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../../components/Title/tabletitle";
import { getCardTypeList, setCardType } from "../../../api/info";
export default {
  props: {
    visible: {
      type: Boolean,
    },
    siteId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      columns: [
        {
          title: "NO",
          customRender: (text, record, index) => {
            return index + 1;
          },
          width: 60,
          align: "center",
        },
        {
          title: this.$t("information.a59"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          width: 100,
          align: "center",
        },
      ],
    };
  },
  watch: {
    visible: {
      handler(newValue) {
        this.loading = true;
        const visible = this.visible;
        const siteId = this.siteId;
        if (visible) {
          const data = {
            siteId,
          };
          getCardTypeList(data)
            .then((res) => {
              // console.log(res);
              this.loading = false;
              const { data } = res;
              data.sort((a, b) => {
                return a.consumeCardType - b.consumeCardType;
              });
              this.data = data;
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        }
      },
    },
  },
  methods: {
    //  卡类型设置弹窗关闭方法
    Cancel() {
      this.$emit("shut");
    },
    // 卡类型设置方法
    cardTypeSet() {
      this.loading = true;
      const data = {
        consumeCardTypeNameBeans: this.data,
        siteId: this.siteId,
      };
      // console.log(data);
      setCardType(data)
        .then((res) => {
          // console.log(res);
          this.loading = false;
          if (res.errorCode == "00") {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
  components: {
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
div /deep/.ant-modal-body {
  padding: 20px 0px;
}
.main {
  height: 400px;
  padding: 0px 20px;
  overflow: auto;
}
</style>